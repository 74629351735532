/* Home page */

import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import PageHead from '../components/PageHead';

export default function Home(props) {
  const ctx = props.pageContext;
  const sitedata = props.data.siteYaml;
  sitedata.language = ctx.locale;

  return (
    <Layout config={sitedata} ctx={ctx} currentpath={props.path}>
      <PageHead lang={ctx.locale} sitename={sitedata.sitename} siteurl={sitedata.siteurl} meta={sitedata.metaDescription} />
    </Layout>
  );
};

export const query = graphql`
  query siteLocaleQ($locale: String, $variation: String) {
    siteYaml(locale: {eq: $locale}, variation: {eq: $variation}) {
      locale
      variation
      homelink
      metaDescription
      sitename
      siteurl
      startstudy
      subtitle
      title
      banner {
        theme
        btntheme
      }
      fixedpanel {
        cta
        btntheme
      }
      navbar {
        theme
      }
      sections {
        id
        linkto
        title
        header
        theme
        text
        data {
          content
          items
        }
      }
      footer {
        links {
          label
          to
        }
        text
        theme
      }
      errorpage {
        header
        subheader
      }
      form {
        button {
          theme
          label
          labelDq
        }
        formfields {
          id
          type
          label
          theme
          backend
          validation
          options {
            id
            value
          }
          default
          fullWidth
        }
      }
      screener {
        pid
        btntheme
        questions {
          q
          proceedIfYes
        }
        questionLabel {
          affirm
          deny
          noun
          prep
        }
        instructions
        successResult {
          header
          message
          theme
          thankyou {
            btntheme
            locationpicked {
              theme
              header
              text
            }
            nolocation {
              theme
              header
              text
            }
          }
        }
        failResult {
          header
          message
          theme
          thankyou {
            theme
            btntheme
            header
            text
          }
        }
        locations {
          addresses {
            city
            lat
            lng
            name
            state
            street
            zip
          }
          cta
          ctabutton
          header
          noSuitableSite
        }
      }
    }
  }`;
